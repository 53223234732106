import { CommonModule } from '@angular/common';
import { Component, inject, input, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CustomerCreateStore } from '@app/features/customer-create/store/customer-create.store';
import { TranslateModule } from '@ngx-translate/core';

const defaultCarrierTypes = {
  normal: '00000000000BONAYOU01',
  recarding: '00000000000BONAYOU03',
};

@Component({
  selector: 'app-carrier-type',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    TranslateModule,
    MatButtonModule,
  ],
  templateUrl: './carrier-type.component.html',
})
export class CarrierTypeComponent implements OnInit {
  control = input.required<FormControl>();
  isRecarding = input.required<boolean>();
  multiplierControl = input.required<FormControl>();

  suggestion: string | null = null;

  private customerCreateStore = inject(CustomerCreateStore);

  vm$ = this.customerCreateStore.vm$;

  ngOnInit() {
    this.suggestion = this.isRecarding() ? defaultCarrierTypes.recarding : defaultCarrierTypes.normal;
    this.multiplierControl().valueChanges.subscribe((multiplier) => {
      this.suggestion = this.isRecarding()
        ? multiplier?.default_renew_carrier_type || defaultCarrierTypes.recarding
        : multiplier?.default_carrier_type || defaultCarrierTypes.normal;
      if (
        (this.isRecarding() && multiplier?.default_renew_carrier_type) ||
        (!this.isRecarding() && multiplier?.default_carrier_type)
      ) {
        this.control().patchValue(this.suggestion);
        this.control().updateValueAndValidity();
      } else {
        this.control().patchValue(null);
        this.control().updateValueAndValidity();
      }
    });
  }

  onHintClick(e: Event) {
    e.stopPropagation();
    this.control().patchValue(this.suggestion);
    this.control().updateValueAndValidity();
  }
}
