import { CdkStepperModule } from '@angular/cdk/stepper';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Person } from '@app/api';
import { AddressFormComponent } from '@app/features/customer-create/components/address-form/address-form.component';
import { CardOrderFormComponent } from '@app/features/customer-create/components/card-order-form/card-order-form.component';
import { CarrierTypeComponent } from '@app/features/customer-create/components/carrier-type/carrier-type.component';
import { ContactPersonsFormComponent } from '@app/features/customer-create/components/contact-persons-form/contact-persons-form.component';
import { DefaultProductRefComponent } from '@app/features/customer-create/components/default-product-ref/default-product-ref.component';
import { GpsUsageGroupComponent } from '@app/features/customer-create/components/gps-usage-group/gps-usage-group.component';
import { LegalFormComponent } from '@app/features/customer-create/components/legal-form/legal-form.component';
import { LiablePersonsFormComponent } from '@app/features/customer-create/components/liable-persons-form/liable-persons-form.component';
import { LoadFeeFormComponent } from '@app/features/customer-create/components/load-fee-form/load-fee-form.component';
import { RegisterNumberComponent } from '@app/features/customer-create/components/register-number/register-number.component';
import { VatZoneComponent } from '@app/features/customer-create/components/vat-zone/vat-zone.component';
import { CustomerCreateStore } from '@app/features/customer-create/store/customer-create.store';
import { buildDeliveryAddressData } from '@app/features/customer-create/utils';
import { removeNullAndEmptyFromObject, valueToCents } from '@app/shared';
import { ClientAutocompleteFieldComponent } from '@app/shared/components/client-autocomplete-field/client-autocomplete-field.component';
import { FieldsetComponent } from '@app/shared/components/fieldset/fieldset.component';
import { ContactPerson } from '@app/shared/models/contact-person';
import { ButtonSpinnerComponent, StepperComponent } from '@givve/ui-kit/components';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslateModule } from '@ngx-translate/core';

const addressStringPattern: RegExp = /[\u0020-\u007E\u00A1-\u00FF]/i;
const addressZipPattern: RegExp = /^\d{5}$/;
export const registerNumberPattern =
  /^([1-9]\d*)(?: (AH|B|BB|BHV|BS|CB|EC|EL|EU|FF|FL|GE|HB|HL|HU|IZ|KA|KI|ME|MÖ|NI|NM|NO|NP|OD|OL|P|PI|PL|RD|RE|RZ|SB|SE|SL))?$/;
const languageCodes = ['de', 'en'];

@Component({
  selector: 'app-customer-create-dialog',
  templateUrl: './customer-create-dialog.component.html',
  styleUrls: ['./customer-create-dialog.component.scss'],
  standalone: true,
  imports: [
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    NgFor,
    AsyncPipe,
    MatStepperModule,
    SvgIconComponent,
    TranslateModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatCheckboxModule,
    MatOptionModule,
    ButtonSpinnerComponent,
    LegalFormComponent,
    RegisterNumberComponent,
    VatZoneComponent,
    GpsUsageGroupComponent,
    DefaultProductRefComponent,
    CarrierTypeComponent,
    LoadFeeFormComponent,
    CardOrderFormComponent,
    ContactPersonsFormComponent,
    LiablePersonsFormComponent,
    StepperComponent,
    CdkStepperModule,
    ClientAutocompleteFieldComponent,
    AddressFormComponent,
    FieldsetComponent,
    MatTooltipModule,
  ],
  providers: [CustomerCreateStore],
})
export class CustomerCreateDialogComponent implements OnInit {
  languageCodes = languageCodes;

  private customerCreateStore = inject(CustomerCreateStore);
  private fb = inject(FormBuilder);

  vm$ = this.customerCreateStore.vm$;

  addressFormGroup = this.fb.group(
    {
      company: [null, [Validators.required, Validators.pattern(addressStringPattern), Validators.maxLength(255)]],
      contact_person: [null, [Validators.pattern(addressStringPattern)]],
      address_line_1: [null, [Validators.required, Validators.pattern(addressStringPattern), Validators.maxLength(50)]],
      address_line_2: [null, [Validators.pattern(addressStringPattern), Validators.maxLength(50)]],
      zip_code: [null, [Validators.required]],
      city: [null, [Validators.required, Validators.pattern(addressStringPattern), Validators.maxLength(20)]],
      country_code: ['276', [Validators.required]],
      delivery_company: [null, [Validators.pattern(addressStringPattern)]],
      delivery_contact_person: [null, [Validators.pattern(addressStringPattern)]],
      delivery_address_line_1: [null, [Validators.pattern(addressStringPattern), Validators.maxLength(50)]],
      delivery_address_line_2: [null, [Validators.pattern(addressStringPattern), Validators.maxLength(50)]],
      delivery_zip_code: [null],
      delivery_city: [null, [Validators.pattern(addressStringPattern), Validators.maxLength(20)]],
      delivery_country_code: [null],
    },
    this.createZipCodeValidator()
  );

  vatFormGroup = this.fb.group({
    vat_number: [null],
    vat_zone: [null, [Validators.required]],
  });

  multiplierFormGroup = this.fb.group({
    multiplier: [null],
  });

  productionsDataFormGroup = this.fb.group({
    gps_usage_group: [null, [Validators.required]],
    default_product_ref: [null, [Validators.required]],
    default_carrier_type: [null, [Validators.required]],
    default_renew_carrier_type: [null, [Validators.required]],
  });

  loadFeeFormGroup = this.fb.group({
    load_fee_percentage: [null, Validators.required],
    load_fee_min: [null, Validators.required],
    unload_fee: [null, Validators.required],
  });

  cardOrderFormGroup = this.fb.group({
    default_card_price: [null, [Validators.required]],
    default_renew_card_price: [null, [Validators.required]],
    default_single_shipping_fee: [null, [Validators.required]],
    default_bulk_shipping_fee: [null, [Validators.required]],
  });

  b2bSuiteFormGroup = this.fb.group({
    email: [null, [Validators.email]],
  });

  contactPersonsFormGroup = this.fb.group({
    contact_persons: this.fb.array([]),
  });

  liablePersonsFormGroup = this.fb.group({
    liable_persons: this.fb.array([]),
  });

  complianceFormGroup = this.fb.group({
    register_not_available: [null],
    legal_form: [null],
    register_type: [null, [Validators.required]],
    register_number: [null, [Validators.required, Validators.pattern(registerNumberPattern)]],
    register_court_id: [null, [Validators.required]],
    kyb_initiation_email: [null, [Validators.email]],
    liablePersonsFormGroup: this.liablePersonsFormGroup,
  });

  settingsFormGroup = this.fb.group({
    language_code: ['de', [Validators.required]],
  });

  accountsFormGroup = this.fb.group({
    multiplierFormGroup: this.multiplierFormGroup,
    b2bSuiteFormGroup: this.b2bSuiteFormGroup,
    contactPersonFormGroup: this.contactPersonsFormGroup,
    settingsFormGroup: this.settingsFormGroup,
  });

  feesFormGroup = this.fb.group({
    cardOrderFormGroup: this.cardOrderFormGroup,
    loadFeeFormGroup: this.loadFeeFormGroup,
  });

  ngOnInit() {
    this.customerCreateStore.loadCarrierTypes();
  }

  onDoneClick() {
    let address = removeNullAndEmptyFromObject(this.addressFormGroup.value);
    const delivery_address = buildDeliveryAddressData(address);
    const multiplier = removeNullAndEmptyFromObject(this.multiplierFormGroup.value.multiplier);
    const loadFee = removeNullAndEmptyFromObject(this.loadFeeFormGroup.value);
    const cardOrder = removeNullAndEmptyFromObject(this.cardOrderFormGroup.value);
    const contactPersons = this.contactPersonsFormGroup?.value?.contact_persons!.filter(
      (c) => (c as ContactPerson).email !== null
    );
    const liablePersons = this.liablePersonsFormGroup?.value?.liable_persons!.filter(
      (l) => (l as Person).last_name !== null
    );

    this.customerCreateStore.createCustomer({
      address,
      ...(Object.keys(delivery_address).length && { delivery_address }),
      ...removeNullAndEmptyFromObject(this.complianceFormGroup.value),
      ...removeNullAndEmptyFromObject(this.vatFormGroup.value),
      ...removeNullAndEmptyFromObject(this.productionsDataFormGroup.value),
      ...removeNullAndEmptyFromObject(this.b2bSuiteFormGroup.value),
      ...removeNullAndEmptyFromObject(this.settingsFormGroup.value),
      ...(!!multiplier?.id && { multiplier_id: multiplier.id }),
      ...(contactPersons.length > 0 && { contact_persons: contactPersons }),
      ...(liablePersons.length > 0 && { liable_persons: liablePersons }),
      load_fee_min: {
        cents: valueToCents(+loadFee?.load_fee_min!),
      },
      unload_fee: {
        cents: valueToCents(+loadFee?.unload_fee!),
      },
      load_fee_percentage: loadFee?.load_fee_percentage,
      default_card_price: {
        cents: valueToCents(+cardOrder?.default_card_price!),
      },
      default_renew_card_price: {
        cents: valueToCents(+cardOrder?.default_renew_card_price!),
      },
      default_single_shipping_fee: {
        cents: valueToCents(+cardOrder?.default_single_shipping_fee!),
      },
      default_bulk_shipping_fee: {
        cents: valueToCents(+cardOrder?.default_bulk_shipping_fee!),
      },
    });
  }

  onB2bSuiteFormDuplicateClick(e: Event) {
    e.stopPropagation();
    if (!this.b2bSuiteFormGroup.controls.email.value) {
      return;
    }
    const contactPersons = this.contactPersonsFormGroup.controls['contact_persons'] as FormArray;
    const firstContactPerson = contactPersons.controls[0];
    firstContactPerson?.patchValue({ email: this.b2bSuiteFormGroup.controls.email.value });
    firstContactPerson?.updateValueAndValidity();
  }

  createZipCodeValidator(): ValidatorFn {
    return ((form: FormGroup): ValidationErrors | null => {
      const country = form.get('country_code')?.value;
      const zipCode = form.get('zip_code')?.value;
      const deliveryCountry = form.get('delivery_country_code')?.value;
      const deliveryZipCode = form.get('delivery_zip_code')?.value;
      let zipInvalid = false;
      let deliveryZipInvalid = false;

      if (country === '276' && !addressZipPattern.test(zipCode)) {
        form.controls['zip_code']?.setErrors({ zipInvalid: true });
        zipInvalid = true;
      }

      if (deliveryCountry === '276' && !addressZipPattern.test(deliveryZipCode)) {
        form.controls['delivery_zip_code']?.setErrors({ zipInvalid: true });
        deliveryZipInvalid = true;
      }

      return {
        zipInvalid,
        deliveryZipInvalid,
      };
    }) as ValidatorFn;
  }
}
