import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouterStateUrl } from './router.interfaces';

const getRouterState = createFeatureSelector<RouterStateUrl>('router');

export const getParams = createSelector(getRouterState, (state: any) =>
  state && state.state ? state.state.params : null
);

export const getQueryParams = createSelector(getRouterState, (state: any) =>
  state && state.state ? state.state.queryParams : null
);

export const getQuery = createSelector(getParams, (params: any) =>
  params && params['query'] ? params['query'] : null
);

export const getShowExcluded = createSelector(getParams, (params: any) =>
  params && params['show_excluded'] ? params['show_excluded'] : null
);

export const getUrl = createSelector(getRouterState, (state: any) => (state && state.state ? state.state.url : null));

export const getListParams = createSelector(getRouterState, (state: any) =>
  // @Todo: simplify names
  state && state.state
    ? {
        sort: state.state.params.sort,
        direction: state.state.params.direction,
      }
    : null
);

export const routerQuery = {
  getParams,
  getQueryParams,
  getUrl,
  getQuery,
  getShowExcluded,
  getListParams,
};
