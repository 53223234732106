// @ts-strict-ignore
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import {
  APP_INITIALIZER,
  DEFAULT_CURRENCY_CODE,
  ErrorHandler,
  Injectable,
  LOCALE_ID,
  enableProdMode,
  importProvidersFrom,
  inject,
} from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_SELECT_CONFIG, MatSelectModule } from '@angular/material/select';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule, Title, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { Router, RouterStateSnapshot, TitleStrategy, provideRouter, withRouterConfig } from '@angular/router';
import { ErrorInterceptorService } from '@app/api/services/error-interceptor.service';
import AppRoutes from '@app/app-routes';
import { AppComponent } from '@app/app.component';
import { TokenInterceptorService } from '@app/auth/services/token-interceptor.service';
import { WithCredentialsInterceptorService } from '@app/auth/services/with-credentials-interceptor.service';
import { AuthEffects } from '@app/auth/state/auth.effects';
import { STATE_KEY, authReducer } from '@app/auth/state/auth.reducer';
import { environment } from '@app/env';
import { CustomSerializer } from '@app/ngrx-router/state/custom-serializer';
import { CsvUploadTranslateLoader } from '@givve/csv-upload';
import { UI_KIT_SVG_ICONS } from '@givve/ui-kit/icons';
import { provideSvgIconsConfig } from '@ngneat/svg-icon';
import { provideEffects } from '@ngrx/effects';
import { RouterStateSerializer, provideRouterStore, routerReducer } from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular-ivy';
import { NgxCurrencyInputMode, provideEnvironmentNgxCurrency } from 'ngx-currency';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { Observable, forkJoin, from, lastValueFrom, map } from 'rxjs';

Sentry.init({
  environment: environment.production ? 'production' : environment.staging ? 'staging' : 'development',
  dsn: environment.sentry.dsn,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/^https:\/\/*givve\.io/],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

registerLocaleData(localeDe);

class CustomTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return forkJoin([
      from(import(`./assets/i18n/${lang}.json`)),
      new CsvUploadTranslateLoader().getTranslation(lang),
    ]).pipe(map((translations) => ({ ...translations[0].default, ...translations[1] })));
  }
}

@Injectable()
class GivvePageTitleStrategy extends TitleStrategy {
  private readonly title = inject(Title);
  private translate = inject(TranslateService);

  constructor() {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    if (title !== undefined) {
      this.title.setTitle(`${environment.staging ? 'Staging - ' : ''}Card Admin - ${this.translate.instant(title)}`);
    }
  }
}

const appInitializerFactory = (translateService: TranslateService) => {
  return () => lastValueFrom(translateService.use(translateLoaderConfig.defaultLanguage));
};

const translateLoaderConfig = {
  loader: { provide: TranslateLoader, useClass: CustomTranslateLoader },
  defaultLanguage: 'de',
};

const storeModuleConfig = {
  runtimeChecks: {
    strictStateImmutability: true,
    strictActionImmutability: true,
  },
};

const dateFormatConfig = {
  parse: {
    dateInput: 'DD/MM/YYYY', // this is how date will be parsed from Input
  },
  display: {
    dateInput: 'DD/MM/YYYY', // this is how date will get displayed on the Input
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

const matFormFieldDefaultOptions = {
  floatLabel: 'always',
};

const customCurrencyMaskConfig = {
  prefix: '',
  suffix: ' €',
  thousands: '.',
  decimal: ',',
  inputMode: NgxCurrencyInputMode.Natural,
  align: 'left',
  allowNegative: true,
  allowZero: true,
  precision: 2,
  nullable: true,
};

const matSelectConfig = {
  typeaheadDebounceInterval: 400,
};

if (environment.production || environment.staging) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(AppRoutes, withRouterConfig({ paramsInheritanceStrategy: 'always' })),
    provideStore({ router: routerReducer, [STATE_KEY]: authReducer }, storeModuleConfig),
    provideRouterStore({ stateKey: 'router' }),
    provideEffects([AuthEffects]),
    provideStoreDevtools({ maxAge: 25, logOnly: environment.production }),
    importProvidersFrom(
      MatSnackBarModule,
      MatDialogModule,
      MatSelectModule,
      MatTooltipModule,
      BrowserModule,
      TranslateModule.forRoot(translateLoaderConfig)
    ),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'de' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    {
      provide: TitleStrategy,
      useClass: GivvePageTitleStrategy,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: WithCredentialsInterceptorService, multi: true },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 50000 } },
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: dateFormatConfig },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: matFormFieldDefaultOptions },
    provideSvgIconsConfig({
      sizes: {
        lg: '28px',
        md: '24px',
        sm: '20px',
        xs: '16px',
      },
      defaultSize: 'sm',
      icons: UI_KIT_SVG_ICONS,
    }),
    provideEnvironmentNgxCurrency(customCurrencyMaskConfig),
    provideEnvironmentNgxMask(),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptorsFromDi()),
    provideEnvironmentNgxCurrency(customCurrencyMaskConfig),
    provideEnvironmentNgxMask(),
    { provide: MAT_SELECT_CONFIG, useValue: matSelectConfig },
  ],
}).catch((error) => console.error('Card Admin :: Initialization error :: ', error));
