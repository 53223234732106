<givve-frame
  [user]="authFacade.user$ | async"
  [version]="version"
  [logoutText]="'Logout'"
  (logout)="onLogout()"
>
  <givve-frame-logo
    data-cy="logo"
    routerLink="/"
    [width]="'213'"
    [height]="'42'"
    logo="../../../../assets/img/up-logo-with-wording.png"
  ></givve-frame-logo>
  <givve-frame-toolbar class="tw-z-50">
    @if (!this.hideGlobalSearch) {
      <app-global-search-field class="tw-w-full tw-pr-6"></app-global-search-field>
    }
    <givve-toolbar-profile [user]="authFacade.user$ | async">
      <button
        mat-menu-item
        (click)="onOldAdminClick()"
      >
        {{ 'common.old_admin' | translate }}
      </button>
    </givve-toolbar-profile>
  </givve-frame-toolbar>
  <givve-frame-nav>
    @if (isHelpDeskUser$ | async) {
      <ng-container>
        <mat-list-item
          (click)="dialog.open(customerCreateDialogComponent, customerCreateDialogConfig)"
          class="tw-cursor-pointer"
        >
          <fa-icon
            icon="user"
            class="tw-mr-[22px] tw-w-[24px] tw-text-center"
            size="xl"
          />
          {{ 'frame.new_customer' | translate }}
        </mat-list-item>
      </ng-container>
    }
    @for (item of navItems$ | async; track item.path) {
      <ng-container>
        @if (!item['children']) {
          <givve-nav-item [item]="item | asNavItem"> {{ item.title }}</givve-nav-item>
        } @else {
          <givve-nav-item-group
            [icon]="item.icon"
            [highlight]="routerHistoryService.currentUrl?.indexOf(item.path) !== -1"
          >
            {{ item.title }}
            <givve-nav-item
              *ngFor="let child of item['children']"
              [item]="child"
            >
              {{ child.title }}</givve-nav-item
            >
          </givve-nav-item-group>
        }
      </ng-container>
    }
  </givve-frame-nav>
  <givve-frame-content data-cy="main-content">
    <router-outlet></router-outlet>
  </givve-frame-content>
</givve-frame>
