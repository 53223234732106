<app-fieldset [title]="'common.contact_persons' | translate">
  <form [formGroup]="formGroup()">
    <ng-container formArrayName="contact_persons">
      @for (contactPersonForm of contactPersons().controls; track contactPersonForm; let i = $index) {
        <div
          class="col-row tw-items-center"
          [formGroup]="contactPersonForm"
        >
          <mat-form-field>
            <mat-label> {{ 'contact-persons-form.name' | translate }} </mat-label>
            <input
              formControlName="name"
              matInput
              trim
            />
          </mat-form-field>
          <mat-form-field class="tw-flex-[0_0_90px]">
            <mat-select formControlName="email_notification">
              <mat-option
                *ngFor="let value of emailNotificationValues"
                [value]="value"
                >{{ value | uppercase }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label> {{ 'contact-persons-form.email' | translate }} </mat-label>
            <input
              formControlName="email"
              matInput
              trim
            />
            <mat-error *ngIf="contactPersonForm.controls.email.errors?.email">
              {{ 'common.errors.email' | translate }}
            </mat-error>
            <mat-error *ngIf="contactPersonForm.controls.email.errors?.required">
              {{ 'common.fill_out_field' | translate }}
            </mat-error>
          </mat-form-field>
          <button
            mat-button
            (click)="removeContactPerson(i)"
            class="tw-flex-[0_0_65px]"
          >
            <svg-icon key="delete"></svg-icon>
          </button>
        </div>
      }
    </ng-container>
  </form>
  <button
    class="icon-text-btn tw-mb-4 tw-mr-4 tw-content-start"
    mat-button
    (click)="addContactPerson()"
  >
    {{ 'common.add_more_contacts' | translate }}
    <svg-icon
      class="ml2"
      fontSize="18px"
      key="add"
    ></svg-icon>
  </button>
</app-fieldset>
