import { CommonModule } from '@angular/common';
import { Component, input, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Multiplier } from '@givve/ui-kit/models';
import { TranslateModule } from '@ngx-translate/core';
import { NgxCurrencyDirective } from 'ngx-currency';

@Component({
  selector: 'app-card-order-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    NgxCurrencyDirective,
    MatButtonModule,
  ],
  templateUrl: './card-order-form.component.html',
})
export class CardOrderFormComponent implements OnInit {
  formGroup = input.required<FormGroup>();
  multiplierControl = input.required<FormControl>();
  multiplier: Multiplier | null = null;
  defaultSuggestions = {
    default_card_price: [10.5, 15.5, 18.5],
    default_renew_card_price: [10.5, 15.5, 18.5],
    default_single_shipping_fee: 1.9,
    default_bulk_shipping_fee: 8,
  };
  suggestions = { ...this.defaultSuggestions };

  ngOnInit() {
    this.multiplierControl().valueChanges.subscribe((multiplier) => {
      this.multiplier = multiplier;
      this.suggestions.default_card_price = multiplier?.default_card_price
        ? [multiplier?.default_card_price.cents / 100]
        : this.defaultSuggestions.default_card_price;
      this.suggestions.default_renew_card_price = multiplier?.default_renew_card_price
        ? [multiplier?.default_renew_card_price.cents / 100]
        : this.defaultSuggestions.default_renew_card_price;
      this.suggestions.default_single_shipping_fee = multiplier?.default_single_shipping_fee
        ? multiplier?.default_single_shipping_fee.cents / 100
        : this.defaultSuggestions.default_single_shipping_fee;
      this.suggestions.default_bulk_shipping_fee = multiplier?.default_bulk_shipping_fee
        ? multiplier?.default_bulk_shipping_fee.cents / 100
        : this.defaultSuggestions.default_bulk_shipping_fee;
    });
  }

  onDefaultCardPriceSuggestionClick(e: Event, suggestion: number) {
    e.stopPropagation();
    this.formGroup().get('default_card_price')?.patchValue(suggestion);
    this.formGroup().get('default_card_price')?.updateValueAndValidity();
  }

  onDefaultRenewCardPriceSuggestionClick(e: Event, suggestion: number) {
    e.stopPropagation();
    this.formGroup().get('default_renew_card_price')?.patchValue(suggestion);
    this.formGroup().get('default_renew_card_price')?.updateValueAndValidity();
  }

  onDefaultSingleShippingFeeSuggestionClick(e: Event, suggestion: number) {
    e.stopPropagation();
    this.formGroup().get('default_single_shipping_fee')?.patchValue(suggestion);
    this.formGroup().get('default_single_shipping_fee')?.updateValueAndValidity();
  }

  onDefaultBulkShippingFeeSuggestionClick(e: Event, suggestion: number) {
    e.stopPropagation();
    this.formGroup().get('default_bulk_shipping_fee')?.patchValue(suggestion);
    this.formGroup().get('default_bulk_shipping_fee')?.updateValueAndValidity();
  }
}
