import { uiKitAddIcon } from './generated/add';
import { uiKitArrowDownIcon } from './generated/arrow-down';
import { uiKitArrowUpIcon } from './generated/arrow-up';
import { uiKitBackIcon } from './generated/back';
import { uiKitBellIcon } from './generated/bell';
import { uiKitCalendarIcon } from './generated/calendar';
import { uiKitCancelIcon } from './generated/cancel';
import { uiKitCardCancelIcon } from './generated/card-cancel';
import { uiKitCardCheckIcon } from './generated/card-check';
import { uiKitCardDefaultIcon } from './generated/card-default';
import { uiKitCardDefectIcon } from './generated/card-defect';
import { uiKitCardLockIcon } from './generated/card-lock';
import { uiKitCardLostIcon } from './generated/card-lost';
import { uiKitCardPasswordIcon } from './generated/card-password';
import { uiKitCardSetupIcon } from './generated/card-setup';
import { uiKitCardSetupBgIcon } from './generated/card-setup-bg';
import { uiKitCardUnlockIcon } from './generated/card-unlock';
import { uiKitCardXIcon } from './generated/card-x';
import { uiKitCheckIcon } from './generated/check';
import { uiKitCloseIcon } from './generated/close';
import { uiKitContentCopyIcon } from './generated/content_copy';
import { uiKitCreditCardIcon } from './generated/credit-card';
import { uiKitCrossRoundedBgIcon } from './generated/cross-rounded-bg';
import { uiKitCrossRoundedBgLightIcon } from './generated/cross-rounded-bg-light';
import { uiKitCsvIcon } from './generated/csv';
import { uiKitDashboardIcon } from './generated/dashboard';
import { uiKitDeleteIcon } from './generated/delete';
import { uiKitDownloadIcon } from './generated/download';
import { uiKitDuplicateIcon } from './generated/duplicate';
import { uiKitEditIcon } from './generated/edit';
import { uiKitEmailIcon } from './generated/email';
import { uiKitEmptyIcon } from './generated/empty';
import { uiKitErrorIcon } from './generated/error';
import { uiKitErrorReportIcon } from './generated/error-report';
import { uiKitEyeIcon } from './generated/eye';
import { uiKitFaqIcon } from './generated/faq';
import { uiKitFilterIcon } from './generated/filter';
import { uiKitFilterActiveIcon } from './generated/filter-active';
import { uiKitFlexIcon } from './generated/flex';
import { uiKitHomeIcon } from './generated/home';
import { uiKitHourglassIcon } from './generated/hourglass';
import { uiKitInfoIcon } from './generated/info';
import { uiKitInterrobangIcon } from './generated/interrobang';
import { uiKitLanguageIcon } from './generated/language';
import { uiKitLinkIcon } from './generated/link';
import { uiKitLinkSquareIcon } from './generated/link-square';
import { uiKitListIcon } from './generated/list';
import { uiKitListTreeSolidIcon } from './generated/list-tree-solid';
import { uiKitLoadOrdersIcon } from './generated/load-orders';
import { uiKitLocationIcon } from './generated/location';
import { uiKitLockIcon } from './generated/lock';
import { uiKitLogoutIcon } from './generated/logout';
import { uiKitLunchIcon } from './generated/lunch';
import { uiKitMapMarkerIcon } from './generated/map-marker';
import { uiKitMenuIcon } from './generated/menu';
import { uiKitMenuFullIcon } from './generated/menu-full';
import { uiKitMerchantPlaceholderIcon } from './generated/merchant-placeholder';
import { uiKitMergeIcon } from './generated/merge';
import { uiKitMoreVertIcon } from './generated/more-vert';
import { uiKitNextIcon } from './generated/next';
import { uiKitOrderIcon } from './generated/order';
import { uiKitParticipantIcon } from './generated/participant';
import { uiKitPartnerPortalIcon } from './generated/partnerportal';
import { uiKitPauseIcon } from './generated/pause';
import { uiKitPaymentLockIcon } from './generated/payment-lock';
import { uiKitPaymentLockUnlockedIcon } from './generated/payment-lock-unlocked';
import { uiKitPinCodeIcon } from './generated/pin-code';
import { uiKitPlayIcon } from './generated/play';
import { uiKitQuestionIcon } from './generated/question';
import { uiKitReloadIcon } from './generated/reload';
import { uiKitReloadDisabledIcon } from './generated/reload-disabled';
import { uiKitScheduledLoadOrdersIcon } from './generated/scheduled-load-orders';
import { uiKitSearchIcon } from './generated/search';
import { uiKitSecurityIcon } from './generated/security';
import { uiKitSecurityOpenIcon } from './generated/security-open';
import { uiKitSettingsIcon } from './generated/settings';
import { uiKitSettingsOctagonIcon } from './generated/settings-octagon';
import { uiKitStoreIcon } from './generated/store';
import { uiKitSuccessIcon } from './generated/success';
import { uiKitSuitcaseIcon } from './generated/suitcase';
import { uiKitSupportIcon } from './generated/support';
import { uiKitTicketIcon } from './generated/ticket';
import { uiKitTransactionDeclinedIcon } from './generated/transaction-declined';
import { uiKitTransactionDepositIcon } from './generated/transaction-deposit';
import { uiKitTransactionStateCardStateChangeIcon } from './generated/transaction-state-card-state-change';
import { uiKitTransactionStateOkIcon } from './generated/transaction-state-ok';
import { uiKitTransactionStateReversalIcon } from './generated/transaction-state-reversal';
import { uiKitTransactionWithdrawIcon } from './generated/transaction-withdraw';
import { uiKitTransactionsIcon } from './generated/transactions';
import { uiKitUnloadOrdersIcon } from './generated/unload-orders';
import { uiKitUploadIcon } from './generated/upload';
import { uiKitUserIcon } from './generated/user';
import { uiKitUserLockedIcon } from './generated/user-locked';
import { uiKitUserConfirmationExpiredIcon } from './generated/user_confirmation_expired';
import { uiKitUserConfirmationOpenIcon } from './generated/user_confirmation_open';
import { uiKitUserEmailResendIcon } from './generated/user_email_resend';
import { uiKitVoucherOwnerIcon } from './generated/voucher-owner';
import { uiKitWaitingIcon } from './generated/waiting';

export const UI_KIT_SVG_ICONS = [
  uiKitStoreIcon,
  uiKitAddIcon,
  uiKitEmailIcon,
  uiKitArrowDownIcon,
  uiKitArrowUpIcon,
  uiKitBackIcon,
  uiKitBellIcon,
  uiKitCalendarIcon,
  uiKitCardCancelIcon,
  uiKitCardCheckIcon,
  uiKitCardXIcon,
  uiKitHomeIcon,
  uiKitLanguageIcon,
  uiKitLinkIcon,
  uiKitListIcon,
  uiKitLocationIcon,
  uiKitPaymentLockUnlockedIcon,
  uiKitPaymentLockIcon,
  uiKitPinCodeIcon,
  uiKitSecurityOpenIcon,
  uiKitSecurityIcon,
  uiKitSearchIcon,
  uiKitSupportIcon,
  uiKitTransactionsIcon,
  uiKitMapMarkerIcon,
  uiKitCardDefectIcon,
  uiKitCardLockIcon,
  uiKitCardUnlockIcon,
  uiKitCardLostIcon,
  uiKitCancelIcon,
  uiKitCheckIcon,
  uiKitCloseIcon,
  uiKitContentCopyIcon,
  uiKitCreditCardIcon,
  uiKitCsvIcon,
  uiKitDashboardIcon,
  uiKitDeleteIcon,
  uiKitDownloadIcon,
  uiKitDuplicateIcon,
  uiKitEditIcon,
  uiKitErrorReportIcon,
  uiKitEyeIcon,
  uiKitFaqIcon,
  uiKitInfoIcon,
  uiKitLoadOrdersIcon,
  uiKitLockIcon,
  uiKitLogoutIcon,
  uiKitMenuIcon,
  uiKitMenuFullIcon,
  uiKitNextIcon,
  uiKitOrderIcon,
  uiKitParticipantIcon,
  uiKitPauseIcon,
  uiKitPlayIcon,
  uiKitReloadIcon,
  uiKitReloadDisabledIcon,
  uiKitScheduledLoadOrdersIcon,
  uiKitUnloadOrdersIcon,
  uiKitSearchIcon,
  uiKitSettingsIcon,
  uiKitSuccessIcon,
  uiKitSuitcaseIcon,
  uiKitTicketIcon,
  uiKitUploadIcon,
  uiKitCardSetupIcon,
  uiKitCardSetupBgIcon,
  uiKitMoreVertIcon,
  uiKitWaitingIcon,
  uiKitCardDefaultIcon,
  uiKitCardPasswordIcon,
  uiKitErrorIcon,
  uiKitQuestionIcon,
  uiKitSettingsOctagonIcon,
  uiKitFilterIcon,
  uiKitFilterActiveIcon,
  uiKitLinkSquareIcon,
  uiKitTransactionsIcon,
  uiKitTransactionDeclinedIcon,
  uiKitTransactionDepositIcon,
  uiKitTransactionStateOkIcon,
  uiKitTransactionWithdrawIcon,
  uiKitTransactionStateCardStateChangeIcon,
  uiKitTransactionStateReversalIcon,
  uiKitNextIcon,
  uiKitWaitingIcon,
  uiKitInterrobangIcon,
  uiKitEmptyIcon,
  uiKitHourglassIcon,
  uiKitCrossRoundedBgLightIcon,
  uiKitCrossRoundedBgIcon,
  uiKitMergeIcon,
  uiKitMerchantPlaceholderIcon,
  uiKitUserIcon,
  uiKitUserConfirmationExpiredIcon,
  uiKitUserConfirmationOpenIcon,
  uiKitUserEmailResendIcon,
  uiKitUserLockedIcon,
  uiKitLunchIcon,
  uiKitFlexIcon,
  uiKitPartnerPortalIcon,
  uiKitVoucherOwnerIcon,
  uiKitListTreeSolidIcon,
];
