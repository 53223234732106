<form
  *ngrxLet="vm$ as vm"
  [formGroup]="form"
  (ngSubmit)="submit()"
>
  <section class="main content-padding">
    <givve-content-toolbar [title]="'common.lunch_tax_rules' | translate"></givve-content-toolbar>
    <mat-card>
      <mat-card-content>
        <div class="flex gap">
          <mat-form-field class="input-width">
            <mat-label>{{ 'tax-rules.date' | translate }}</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              formControlName="date"
              required
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="flex gap">
          <mat-form-field class="input-width">
            <mat-label>{{ 'tax-rules.max_credit_amount' | translate }}</mat-label>
            <input
              matInput
              currencyMask
              required
              formControlName="max_credit_amount"
            />
          </mat-form-field>

          <mat-form-field class="input-width">
            <mat-label>{{ 'tax-rules.transaction_amount' | translate }}</mat-label>
            <input
              matInput
              currencyMask
              required
              formControlName="transaction_amount"
            />
          </mat-form-field>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button
          color="primary"
          mat-flat-button
          type="submit"
          class="mla button-with-spinner"
          [disabled]="form.invalid || form.pristine || vm.statusType === 'LOADING'"
        >
          <givve-button-spinner [loading]="vm.statusType === 'LOADING'">
            {{ 'common.send' | translate }}
          </givve-button-spinner>
        </button>
      </mat-card-actions>
    </mat-card>
  </section>

  @if (vm.statusType === 'DATA') {
    <section class="main content-padding">
      <h3>{{ 'common.result' | translate }}</h3>
      <table
        mat-table
        [dataSource]="[vm.lunchTaxRules]"
        class="mat-elevation-z8"
      >
        <ng-container matColumnDef="credit_amount">
          <th
            mat-header-cell
            *matHeaderCellDef
          >
            {{ 'tax-rules.credit_amount' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
          >
            <givve-amount [amount]="element.credit_amount" />
          </td>
        </ng-container>
        <ng-container matColumnDef="flat_taxed_amount">
          <th
            mat-header-cell
            *matHeaderCellDef
          >
            {{ 'tax-rules.flat_taxed_amount' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
          >
            <givve-amount [amount]="element.flat_taxed_amount" />
          </td>
        </ng-container>
        <ng-container matColumnDef="tax_free_amount">
          <th
            mat-header-cell
            *matHeaderCellDef
          >
            {{ 'tax-rules.tax_free_amount' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
          >
            <givve-amount [amount]="element.tax_free_amount" />
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumnsResult"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumnsResult"
        ></tr>
      </table>
    </section>
    <section class="main content-padding">
      <h3>{{ 'common.debugging' | translate }}</h3>
      <table
        mat-table
        [dataSource]="[vm.lunchTaxRules]"
        class="mat-elevation-z8"
      >
        <ng-container matColumnDef="tax_rule_class">
          <th
            mat-header-cell
            *matHeaderCellDef
          >
            tax_rule_class
          </th>
          <td
            mat-cell
            *matCellDef="let element"
          >
            {{ element.tax_rule_class }}
          </td>
        </ng-container>
        <ng-container matColumnDef="effective_max_credit_amount">
          <th
            mat-header-cell
            *matHeaderCellDef
          >
            effective_max_credit_amount
          </th>
          <td
            mat-cell
            *matCellDef="let element"
          >
            <givve-amount [amount]="element.effective_max_credit_amount" />
          </td>
        </ng-container>
        <ng-container matColumnDef="effective_flat_tax_limit">
          <th
            mat-header-cell
            *matHeaderCellDef
          >
            effective_flat_tax_limit
          </th>
          <td
            mat-cell
            *matCellDef="let element"
          >
            <givve-amount [amount]="element.effective_flat_tax_limit" />
          </td>
        </ng-container>
        <ng-container matColumnDef="effective_max_direct_tax_free_amount">
          <th
            mat-header-cell
            *matHeaderCellDef
          >
            effective_max_direct_tax_free_amount
          </th>
          <td
            mat-cell
            *matCellDef="let element"
          >
            <givve-amount [amount]="element.effective_max_direct_tax_free_amount" />
          </td>
        </ng-container>
        <ng-container matColumnDef="effective_max_transferred_tax_free_amount">
          <th
            mat-header-cell
            *matHeaderCellDef
          >
            effective_max_transferred_tax_free_amount
          </th>
          <td
            mat-cell
            *matCellDef="let element"
          >
            <givve-amount [amount]="element.effective_max_transferred_tax_free_amount" />
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumnsDebugging"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumnsDebugging"
        ></tr>
      </table>
    </section>
  }
</form>
