<mat-form-field>
  <mat-label>
    {{ 'common.carriertype' | translate }} {{ isRecarding() ? '(' + ('common.renew' | translate) + ')' : null }}
  </mat-label>
  <mat-select
    [formControl]="control()"
    required
  >
    <mat-option
      *ngFor="let carrierType of isRecarding() ? (vm$ | async).carrierTypesRecarding : (vm$ | async).carrierTypes"
      [value]="carrierType.exceet_id"
    >
      {{ carrierType.description }}
    </mat-option>
  </mat-select>
  <button
    mat-stroked-button
    matSuffix
    (click)="onHintClick($event)"
  >
    {{ 'common.carrier_types.' + suggestion | translate }}
  </button>
  <mat-error *ngIf="control().errors?.required">
    {{ 'common.fill_out_field' | translate }}
  </mat-error>
</mat-form-field>
