import { CommonModule } from '@angular/common';
import { Component, computed, inject, input, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FieldsetComponent } from '@app/shared/components/fieldset/fieldset.component';
import { CountryService } from '@givve/ui-kit/services';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslateModule } from '@ngx-translate/core';
import { orderBy } from 'lodash-es';

@Component({
  selector: 'app-liable-persons-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    SvgIconComponent,
    MatButtonModule,
    FieldsetComponent,
    MatDatepickerModule,
    MatSelectModule,
  ],
  templateUrl: './liable-persons-form.component.html',
  styleUrls: ['./liable-persons-form.component.scss'],
})
export class LiablePersonsFormComponent implements OnInit {
  formGroup = input.required<FormGroup>();

  protected countryService = inject(CountryService);
  protected sortedCountriesWithNumericCode = orderBy(this.countryService.countriesWithNumericCode, 'name');

  liablePersons = computed(() => {
    return this.formGroup().controls['liable_persons'] as FormArray<FormGroup>;
  });

  private fb = inject(FormBuilder);

  ngOnInit() {
    this.addLiablePerson();
  }

  addLiablePerson() {
    const liablePersonForm = this.fb.group({
      first_name: new FormControl(null, Validators.required),
      last_name: new FormControl(null, Validators.required),
      date_of_birth: new FormControl(null, Validators.required),
      nationality: new FormControl(null, Validators.required),
    });

    this.liablePersons().push(liablePersonForm);
  }

  removeLiablePerson(index: number) {
    this.liablePersons().removeAt(index);
  }
}
