import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CarrierType } from '@app/api';
import { CarrierTypesHttpService } from '@app/api/services/carrier-types-http.service';
import { ClientService } from '@app/features/clients/shared/services/client.service';
import { Customer, Multiplier } from '@givve/ui-kit/models';
import { NotificationService } from '@givve/ui-kit/services';
import { Errors } from '@givve/ui-kit/utils';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { TranslateService } from '@ngx-translate/core';
import { Observable, forkJoin, mergeMap, tap } from 'rxjs';

export interface CustomerCreateState {
  customerCreating: boolean;
  carrierTypes: CarrierType[] | null;
  carrierTypesRecarding: CarrierType[] | null;
}

export const DEFAULT_STATE: CustomerCreateState = {
  customerCreating: false,
  carrierTypes: null,
  carrierTypesRecarding: null,
};

@Injectable()
export class CustomerCreateStore extends ComponentStore<CustomerCreateState> {
  readonly vm$ = this.select((state) => state);

  constructor(
    private notification: NotificationService,
    private translate: TranslateService,
    private carrierTypesHttpService: CarrierTypesHttpService,
    private clientService: ClientService,
    private dialog: MatDialog,
    private router: Router
  ) {
    super(DEFAULT_STATE);
  }

  readonly createCustomer = this.effect((data$: Observable<any>) =>
    data$.pipe(
      tap(() => {
        this.patchState({
          customerCreating: true,
        });
      }),
      mergeMap((data) => {
        return this.clientService
          .setHttpService('customer')
          .createClient(data)
          .pipe(
            tapResponse(
              (client: Customer | Multiplier) => {
                this.patchState({
                  customerCreating: false,
                });
                this.dialog.closeAll();
                this.router.navigate(['/customers', client.id]);
                this.notification.open({
                  message: this.translate.instant('customer-create.store.customer_created_successfully'),
                });
              },
              (error: any) => {
                this.patchState({
                  customerCreating: false,
                });
                this.notification.open({
                  message: Errors.getApiErrorMessage(error) || this.translate.instant('common.errors.general'),
                });
              }
            )
          );
      })
    )
  );

  readonly loadCarrierTypes = this.effect((trigger$: Observable<void>) =>
    trigger$.pipe(
      mergeMap(() =>
        forkJoin([
          this.carrierTypesHttpService.getAllObjects({ filter: { recarding: { $in: 'true' } } }),
          this.carrierTypesHttpService.getAllObjects({ filter: { recarding: { $in: 'false' } } }),
        ]).pipe(
          tapResponse(
            ([carrierTypesRecarding, carrierTypes]: [CarrierType[], CarrierType[]]) =>
              this.patchState({
                carrierTypes,
                carrierTypesRecarding,
              }),
            (error: any) => {
              this.notification.open({
                message: Errors.getApiErrorMessage(error) || this.translate.instant('common.errors.general'),
              });
            }
          )
        )
      )
    )
  );
}
