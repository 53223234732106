<mat-form-field>
  <mat-label> {{ 'common.vat-zone' | translate }} </mat-label>
  <mat-select
    [formControl]="control()"
    required
  >
    <mat-option
      *ngFor="let zone of validZones"
      [value]="zone"
    >
      {{ 'common.vat_zones.' + zone | translate }}
    </mat-option>
  </mat-select>
  <button
    *ngIf="suggestion"
    mat-stroked-button
    matSuffix
    (click)="onHintClick($event)"
  >
    {{ 'common.vat_zones.' + suggestion | translate }}
  </button>
  <mat-error *ngIf="control().errors?.required">
    {{ 'common.fill_out_field' | translate }}
  </mat-error>
</mat-form-field>
