<section class="main content-padding">
  <givve-content-toolbar [title]="'common.push_notification' | translate"></givve-content-toolbar>
  <mat-card>
    <mat-card-content>
      <form
        [formGroup]="form"
        autocomplete="off"
      >
        <mat-form-field class="input-field-without-padding">
          <mat-label> Type </mat-label>
          <mat-select
            formControlName="type"
            (selectionChange)="onTypeSelectionChanged()"
          >
            @for (type of types; track type) {
              <mat-option [value]="type">
                {{ 'push-notification.component.' + type | translate }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>

        @if (
          form.get('type').value === 'voucher_transaction_successful' ||
          form.get('type').value === 'voucher_transaction_declined' ||
          form.get('type').value === 'voucher_loaded'
        ) {
          <mat-form-field class="input-field-without-padding">
            <mat-label>
              {{ 'push-notification.component.voucher_transaction_id' | translate }}
            </mat-label>
            <input
              formControlName="voucher_transaction_id"
              matInput
              trim
            />
          </mat-form-field>
        }

        @if (
          form.get('type').value === 'voucher_configuration_update' ||
          form.get('type').value === 'pending_3ds_transaction'
        ) {
          <mat-form-field class="input-field-without-padding">
            <mat-label>
              {{ 'push-notification.component.voucher_token' | translate }}
            </mat-label>
            <input
              formControlName="voucher_token"
              matInput
              trim
            />
          </mat-form-field>
        }

        @if (form.get('type').value === 'pending_3ds_transaction') {
          <mat-form-field class="input-field-without-padding">
            <mat-label>
              {{ 'push-notification.component.transaction_id' | translate }}
            </mat-label>
            <input
              formControlName="transaction_id"
              matInput
              trim
            />
          </mat-form-field>
          <mat-form-field class="input-field-without-padding">
            <mat-label>
              {{ 'push-notification.component.transaction_amount' | translate }}
            </mat-label>
            <input
              formControlName="transaction_amount"
              currencyMask
              matInput
              trim
            />
          </mat-form-field>
          <mat-form-field class="input-field-without-padding">
            <mat-label>
              {{ 'push-notification.component.transaction_time' | translate }}
            </mat-label>
            <input
              type="datetime-local"
              formControlName="transaction_time"
              matInput
              trim
            />
          </mat-form-field>
          <mat-form-field class="input-field-without-padding">
            <mat-label>
              {{ 'push-notification.component.merchant_name' | translate }}
            </mat-label>
            <input
              formControlName="merchant_name"
              matInput
              trim
            />
          </mat-form-field>
        }
        <div class="btn-right-row">
          <button
            color="primary"
            mat-flat-button
            (click)="onSubmit()"
            class="button-with-spinner"
            [disabled]="form.invalid || form.pristine || (isProcessing$ | async)"
          >
            <givve-button-spinner [loading]="isProcessing$ | async">
              {{ 'common.send' | translate }}
            </givve-button-spinner>
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</section>
