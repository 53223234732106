import { CommonModule } from '@angular/common';
import { Component, input, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { VALID_COUNTRIES } from '@app/features/customer-create/constants';
import { buildDeliveryAddressData } from '@app/features/customer-create/utils';
import { removeNullAndEmptyFromObject } from '@app/shared';
import { TranslateModule } from '@ngx-translate/core';
import * as i18nIsoCountries from 'i18n-iso-countries';

@Component({
  selector: 'app-address-form',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    CommonModule,
    MatSelectModule,
    MatCheckboxModule,
  ],
  templateUrl: './address-form.component.html',
})
export class AddressFormComponent implements OnInit {
  addressFormGroup = input.required<FormGroup>();

  shouldRequireDeliveryFields = false;
  countryOptions = VALID_COUNTRIES.map((code) => ({
    name: i18nIsoCountries.getName(code, 'de', { select: 'official' }),
    code,
  }));
  isSameAddressesChecked = true;

  ngOnInit() {
    this.addressFormGroup().valueChanges.subscribe((formGroupValue) => {
      const deliveryAddress = removeNullAndEmptyFromObject(buildDeliveryAddressData(formGroupValue));
      this.shouldRequireDeliveryFields = Object.keys(deliveryAddress as object).length > 0;
    });
  }
}
