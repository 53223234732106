<mat-form-field>
  <mat-label> {{ 'default-product-ref.label' | translate }} </mat-label>
  <mat-select
    required
    [formControl]="control()"
  >
    <mat-option
      *ngFor="let productRef of productRefs"
      [value]="productRef"
    >
      {{ 'common.product_ref.' + productRef | translate }}
    </mat-option>
  </mat-select>
  <button
    *ngIf="suggestion"
    mat-stroked-button
    matSuffix
    (click)="onHintClick($event)"
  >
    {{ 'common.product_ref.' + suggestion | translate }}
  </button>
  <mat-error *ngIf="control().errors?.required">
    {{ 'common.fill_out_field' | translate }}
  </mat-error>
</mat-form-field>
