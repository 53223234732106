<form
  [formGroup]="addressFormGroup()"
  class="tw-flex tw-gap-6"
>
  <div class="tw-flex tw-w-full tw-flex-col">
    <h4 class="mt0 mb5">{{ 'common.billing_address' | translate }}</h4>
    <mat-form-field>
      <mat-label> {{ 'common.company' | translate }} </mat-label>
      <input
        formControlName="company"
        matInput
        required
        trim
      />
      <mat-error *ngIf="addressFormGroup().controls.company.errors?.required">
        {{ 'common.fill_out_field' | translate }}
      </mat-error>
      <mat-error *ngIf="addressFormGroup().controls.company.errors?.pattern">
        {{ 'common.wrong_pattern_field' | translate }}
      </mat-error>
      <mat-error *ngIf="addressFormGroup().controls.company.errors?.maxlength">
        {{ 'common.errors.maxlength.length' | translate: { maxlength: 255 } }}
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label> {{ 'common.contact_person' | translate }} </mat-label>
      <input
        formControlName="contact_person"
        matInput
        trim
      />
      <mat-error *ngIf="addressFormGroup().controls.contact_person.errors?.pattern">
        {{ 'common.wrong_pattern_field' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label> {{ 'common.address.address_line_1' | translate }} </mat-label>
      <input
        formControlName="address_line_1"
        matInput
        required
        trim
      />
      <mat-error *ngIf="addressFormGroup().controls.address_line_1.errors?.required">
        {{ 'common.fill_out_field' | translate }}
      </mat-error>
      <mat-error *ngIf="addressFormGroup().controls.address_line_1.errors?.pattern">
        {{ 'common.wrong_pattern_field' | translate }}
      </mat-error>
      <mat-error *ngIf="addressFormGroup().controls.address_line_1.errors?.maxlength">
        {{ 'common.errors.maxlength.length' | translate: { maxlength: 50 } }}
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label> {{ 'common.address.address_line_2' | translate }} </mat-label>
      <input
        formControlName="address_line_2"
        matInput
        trim
      />
      <mat-error *ngIf="addressFormGroup().controls.address_line_2.errors?.pattern">
        {{ 'common.wrong_pattern_field' | translate }}
      </mat-error>
      <mat-error *ngIf="addressFormGroup().controls.address_line_2.errors?.maxlength">
        {{ 'common.errors.maxlength.length' | translate: { maxlength: 50 } }}
      </mat-error>
    </mat-form-field>
    <div class="tw-grid tw-max-w-[800px] tw-grid-cols-2 tw-gap-2">
      <mat-form-field>
        <mat-label> {{ 'common.address.zip_code' | translate }} </mat-label>
        <input
          formControlName="zip_code"
          matInput
          required
          trim
        />
        <mat-error *ngIf="addressFormGroup().controls.zip_code.errors?.required">
          {{ 'common.fill_out_field' | translate }}
        </mat-error>
        <mat-error *ngIf="addressFormGroup().errors?.zipInvalid">
          {{ 'common.errors.zip_code_invalid' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label> {{ 'common.address.city' | translate }} </mat-label>
        <input
          formControlName="city"
          matInput
          required
          trim
        />
        <mat-error *ngIf="addressFormGroup().controls.city.errors?.required">
          {{ 'common.fill_out_field' | translate }}
        </mat-error>
        <mat-error *ngIf="addressFormGroup().controls.city.errors?.pattern">
          {{ 'common.wrong_pattern_field' | translate }}
        </mat-error>
        <mat-error *ngIf="addressFormGroup().controls.city.errors?.maxlength">
          {{ 'common.errors.maxlength.length' | translate: { maxlength: 20 } }}
        </mat-error>
      </mat-form-field>
    </div>
    <mat-form-field>
      <mat-label> {{ 'common.address.country' | translate }} </mat-label>
      <mat-select
        required
        formControlName="country_code"
      >
        <mat-option
          *ngFor="let country of countryOptions"
          [value]="country.code"
        >
          {{ country.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="addressFormGroup().controls.country_code.errors?.required">
        {{ 'common.fill_out_field' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-checkbox
      [checked]="isSameAddressesChecked"
      (change)="isSameAddressesChecked = !isSameAddressesChecked"
      disableRipple
      >{{ 'address-form.delivery_address_billing_address' | translate }}</mat-checkbox
    >
  </div>
  <div class="tw-flex tw-w-full tw-flex-col">
    @if (!isSameAddressesChecked) {
      <h4 class="mt0 mb5">{{ 'common.delivery_address' | translate }}</h4>
      <mat-form-field>
        <mat-label> {{ 'common.company' | translate }} </mat-label>
        <input
          formControlName="delivery_company"
          matInput
          trim
          [required]="shouldRequireDeliveryFields"
        />
        <mat-error *ngIf="addressFormGroup().controls.delivery_company.errors?.pattern">
          {{ 'common.wrong_pattern_field' | translate }}
        </mat-error>
        <mat-error *ngIf="addressFormGroup().controls.delivery_company.errors?.required">
          {{ 'common.fill_out_field' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label> {{ 'common.contact_person' | translate }} </mat-label>
        <input
          formControlName="delivery_contact_person"
          matInput
          trim
        />
        <mat-error *ngIf="addressFormGroup().controls.delivery_contact_person.errors?.pattern">
          {{ 'common.wrong_pattern_field' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label> {{ 'common.address.address_line_1' | translate }} </mat-label>
        <input
          formControlName="delivery_address_line_1"
          matInput
          trim
          [required]="shouldRequireDeliveryFields"
        />
        <mat-error *ngIf="addressFormGroup().controls.delivery_address_line_1.errors?.pattern">
          {{ 'common.wrong_pattern_field' | translate }}
        </mat-error>
        <mat-error *ngIf="addressFormGroup().controls.delivery_address_line_1.errors?.maxlength">
          {{ 'common.errors.maxlength.length' | translate: { maxlength: 50 } }}
        </mat-error>
        <mat-error *ngIf="addressFormGroup().controls.delivery_address_line_1.errors?.required">
          {{ 'common.fill_out_field' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label> {{ 'common.address.address_line_2' | translate }} </mat-label>
        <input
          formControlName="delivery_address_line_2"
          matInput
          trim
        />
        <mat-error *ngIf="addressFormGroup().controls.delivery_address_line_2.errors?.pattern">
          {{ 'common.wrong_pattern_field' | translate }}
        </mat-error>
        <mat-error *ngIf="addressFormGroup().controls.delivery_address_line_2.errors?.maxlength">
          {{ 'common.errors.maxlength.length' | translate: { maxlength: 50 } }}
        </mat-error>
      </mat-form-field>
      <div class="tw-grid tw-max-w-[800px] tw-grid-cols-2 tw-gap-2">
        <mat-form-field>
          <mat-label> {{ 'common.address.zip_code' | translate }} </mat-label>
          <input
            formControlName="delivery_zip_code"
            matInput
            trim
            [required]="shouldRequireDeliveryFields"
          />
          <mat-error *ngIf="addressFormGroup().errors?.deliveryZipInvalid">
            {{ 'common.errors.zip_code_invalid' | translate }}
          </mat-error>
          <mat-error *ngIf="addressFormGroup().controls.delivery_zip_code.errors?.required">
            {{ 'common.fill_out_field' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label> {{ 'common.address.city' | translate }} </mat-label>
          <input
            formControlName="delivery_city"
            matInput
            trim
            [required]="shouldRequireDeliveryFields"
          />
          <mat-error *ngIf="addressFormGroup().controls.delivery_city.errors?.pattern">
            {{ 'common.wrong_pattern_field' | translate }}
          </mat-error>
          <mat-error *ngIf="addressFormGroup().controls.delivery_city.errors?.maxlength">
            {{ 'common.errors.maxlength.length' | translate: { maxlength: 20 } }}
          </mat-error>
          <mat-error *ngIf="addressFormGroup().controls.delivery_city.errors?.required">
            {{ 'common.fill_out_field' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <mat-form-field>
        <mat-label> {{ 'common.address.country' | translate }} </mat-label>
        <mat-select
          required
          formControlName="delivery_country_code"
          [required]="shouldRequireDeliveryFields"
        >
          <mat-option
            *ngFor="let country of countryOptions"
            [value]="country.code"
          >
            {{ country.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="addressFormGroup().controls.delivery_country_code.errors?.required">
          {{ 'common.fill_out_field' | translate }}
        </mat-error>
      </mat-form-field>
    }
  </div>
</form>
