import { AsyncPipe } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { NavigationCancel, Router, RouterOutlet } from '@angular/router';
import { TokenService } from '@app/auth';
import * as CONSTANTS from '@app/auth/auth.constants';
import { AuthFacade } from '@app/auth/state/auth.facade';
import { LoadingComponent, LoadingService } from '@app/core';
import { JsonFormatterService } from '@app/shared/services/json-formatter.service';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterHistoryService } from '@givve/ui-kit/services';
import * as ICONS from './app-icons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet, AsyncPipe, LoadingComponent, FontAwesomeModule],
})
export class AppComponent implements OnInit {
  public loadingService = inject(LoadingService);
  private tokenService = inject(TokenService);
  private authFacade = inject(AuthFacade);
  private router = inject(Router);
  private routerHistoryService = inject(RouterHistoryService);
  private jsonFormatterService = inject(JsonFormatterService);

  constructor() {
    const library = inject(FaIconLibrary);

    library.addIcons(...Object.values(ICONS));
  }

  ngOnInit() {
    this.initAuth();
    this.routerHistoryService.init();
    this.jsonFormatterService.init();
    this.initCancelledRouteBeforeLogin();
  }

  private initAuth() {
    if (this.tokenService.isTokenValid()) {
      this.authFacade.init();
    } else {
      this.loadingService.disableLoading();
    }
  }

  // When we navigate to an app route directly from the browser's address bar
  // the AuthGuard service is activated and checks for a valid user session
  //
  // If there is no valid session the user is redirected to the login page. Said
  // that we need to track the cancelled route so after a successful login we can
  // redirect back
  private initCancelledRouteBeforeLogin() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationCancel && !this.tokenService.isTokenValid()) {
        CONSTANTS.cancelledRouteBeforeLogin.next(event.url);
      }
    });
  }
}
