import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import * as CONSTANTS from '@app/auth/auth.constants';
import { AuthService } from '@app/auth/services/auth.service';
import { TokenService } from '@app/auth/services/token.service';
import { LoadingService } from '@app/core';
import { NotificationService, RouterHistoryService } from '@givve/ui-kit/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Subject, catchError, filter, interval, map, mergeMap, of, switchMap, takeUntil, tap } from 'rxjs';
import {
  ActionsUnion,
  forceLogout,
  init,
  initFailure,
  initSuccess,
  login,
  loginFailure,
  loginSuccess,
  logout,
  refreshSession,
} from './auth.actions';

@Injectable()
export class AuthEffects {
  private actions$ = inject<Actions<ActionsUnion>>(Actions<ActionsUnion>);
  private tokenService = inject(TokenService);
  private authService = inject(AuthService);
  private loadingService = inject(LoadingService);
  private router = inject(Router);
  private notification = inject(NotificationService);
  private translate = inject(TranslateService);
  private routerHistoryService = inject(RouterHistoryService);
  // on login form submit
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      switchMap((action: any) =>
        this.authService.login(action.payload).pipe(
          tap((access_token: string) => this.tokenService.setToken(access_token)),
          map(() => loginSuccess()),
          catchError(() =>
            of(loginFailure({ error: this.translate.instant('login.component.login.login_did_not_work') }))
          )
        )
      )
    )
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginSuccess),
        switchMap(() =>
          CONSTANTS.cancelledRouteBeforeLogin.pipe(
            tap((cancelledUrl) => {
              // Clear any previous notification snackbars
              this.notification.clear();

              const { previousUrl } = this.routerHistoryService;

              const latestUserUrl = previousUrl || cancelledUrl;
              if (latestUserUrl) {
                this.router.navigateByUrl(latestUserUrl);
              } else {
                this.router.navigateByUrl('/');
              }
            })
          )
        )
      ),
    { dispatch: false }
  );

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(init, loginSuccess),
      mergeMap((action) => {
        return this.authService.getUser('me').pipe(
          tap(() => {
            if (action.type === init().type) {
              this.loadingService.disableLoading();
            }
          }),
          map((user) => initSuccess({ user })),
          catchError(() => {
            this.loadingService.disableLoading();
            return of(initFailure({}));
          })
        );
      })
    )
  );

  refreshTokenPeriodically$ = createEffect(() =>
    this.actions$.pipe(
      ofType(init, loginSuccess),
      switchMap(() =>
        interval(CONSTANTS.REFRESH_TOKEN_PERIOD_IN_MS).pipe(
          // Check after "REFRESH_TOKEN_PERIOD_IN_MS" ms if the access token is about to expire.
          // Because the access token acts as a refresh token, we do not want access token to expire
          // but rather request for a new one, few minutes/seconds before.
          //
          // Note that the "REFRESH_TOKEN_PERIOD_IN_MS" should be less than, the 2 minutes of extra time
          // we have added to determine when the token is about to expire.
          //
          // More info at https://app.asana.com/0/1202120806432471/1204030922281525
          filter(() => moment().add('2', 'minute').isAfter(this.tokenService.getExpirationTime(), 'minute')),
          switchMap(() =>
            this.authService.refresh(this.tokenService.getAccessToken()).pipe(
              tap((access_token) => {
                this.tokenService.setToken(access_token as unknown as string);
              }),
              map(() => refreshSession()),
              catchError(() => of(forceLogout({ error: this.translate.instant('common.session_expired') })))
            )
          ),
          takeUntil(this.unsubscribeRefreshTokenIntervals$)
        )
      )
    )
  );

  clearSession$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logout, forceLogout, initFailure),
        tap(() => {
          this.unsubscribeRefreshTokenIntervals$.next();
          this.removeAndNavigate();
        })
      ),
    { dispatch: false }
  );

  notification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(forceLogout, initFailure),
        tap(({ error }) => {
          if (error) {
            this.notification.clear();
            this.notification.open({ message: error });
            this.notification.reset();
          }
        })
      ),
    { dispatch: false }
  );

  private removeAndNavigate(url = null) {
    this.tokenService.removeTokens();
    this.router.navigateByUrl(url || CONSTANTS.LOGIN_PATH);
  }

  private unsubscribeRefreshTokenIntervals$ = new Subject<void>();
}
