// @ts-strict-ignore
import { createReducer, on } from '@ngrx/store';

import * as fromActions from './auth.actions';
import { UserAdmin } from '@app/api/interfaces/user.interface';

export const STATE_KEY = 'auth';

export type LoggedIn = '0' | '1';

export interface Auth {
  loggedIn: LoggedIn;
  error: string;
  loading: boolean;
  user: UserAdmin;
}

export interface AuthState {
  readonly auth: Auth;
}

export const initialState: Auth = {
  loggedIn: '0',
  error: null,
  loading: false,
  user: null,
};

const reducer = createReducer<Auth>(
  initialState,
  on(
    fromActions.login,
    () =>
      <Auth>{
        ...initialState,
        loading: true,
      }
  ),
  on(
    fromActions.logout,
    fromActions.forceLogout,
    () =>
      <Auth>{
        ...initialState,
      }
  ),
  on(
    fromActions.loginSuccess,
    (state) =>
      <Auth>{
        ...state,
        loading: false,
        loggedIn: '1',
      }
  ),
  on(
    fromActions.loginFailure,
    (state, { error }) =>
      <Auth>{
        ...state,
        loading: false,
        error,
      }
  ),
  on(
    fromActions.initSuccess,
    (state, { user }) =>
      <Auth>{
        ...state,
        loggedIn: '1',
        user,
      }
  ),
  on(
    fromActions.initFailure,
    (state) =>
      <Auth>{
        ...state,
        user: null,
      }
  )
);

export function authReducer(state: Auth, action: fromActions.ActionsUnion): Auth {
  return reducer(state, action);
}
