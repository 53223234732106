<givve-fullscreen-layout class="full-screen-bg">
  <div class="full-screen-wrapper">
    <div class="full-screen-content">
      <div
        data-cy="not-found-note"
        class="headline"
      >
        {{ 'common.not_found' | translate }}
      </div>
      <div
        data-cy="not-found-text"
        class="text"
        [innerHTML]="'not_found.component.text' | translate"
      ></div>
      <div
        data-cy="not-found-icon"
        class="icon"
      ></div>
      <div class="back-link">
        <button
          data-cy="link-button"
          mat-raised-button
          color="primary"
          (click)="onBack()"
        >
          @if (isLoggedIn) {
            <span>{{ 'not_found.component.go_to_home' | translate }}</span>
          }
          @if (!isLoggedIn) {
            <span>{{ 'not_found.component.go_to_login' | translate }}</span>
          }
        </button>
      </div>
    </div>
  </div>
</givve-fullscreen-layout>
