// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { URITemplate } from '@app/api/services/uri-template';
import { environment } from '@app/env';
import type { ApiResponse, User } from '@givve/ui-kit/models';
import { BaseHttpService } from '@givve/ui-kit/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginPayload } from '../state/auth.facade';

const mapAccessors = (payload) => ({
  ...payload,
});

const adminApiUrlV1 = environment.adminApiUrlV1;

@Injectable({ providedIn: 'root' })
export class AuthService extends BaseHttpService {
  uri = new URITemplate(`{+api_base}{+path}`);

  login(payload: LoginPayload): Observable<any> {
    const authUri = this.uri.build({ api_base: adminApiUrlV1, path: '/sessions' });
    return this.post(authUri, mapAccessors(payload), null, 'text');
  }

  getUser(id: string): Observable<User> {
    const url = this.uri.build({ api_base: adminApiUrlV1, path: `/admin_users/${id}` });
    return this.get(url).pipe(map((res: ApiResponse<User>) => res.data));
  }

  /**
   * Based on the current API implementation there is not support for refresh token per se. The
   * access token in the /api/admin/sessions acts as a refresh token on a PUT request
   *
   * More info at https://app.asana.com/0/1202120806432471/1204030922281525
   */
  refresh(token: string) {
    const authUri = this.uri.build({ api_base: adminApiUrlV1, path: '/sessions' });
    return this.put<string>(
      authUri,
      null,
      {
        Authorization: `Bearer ${token}`,
      },
      'text'
    );
  }
}
