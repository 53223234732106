// @ts-strict-ignore
import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthFacade } from '@app/auth/state/auth.facade';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';

import { MatButtonModule } from '@angular/material/button';
import { FullscreenLayoutComponent } from '@givve/ui-kit/components';

@UntilDestroy()
@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  standalone: true,
  imports: [FullscreenLayoutComponent, MatButtonModule, TranslateModule],
})
export class NotFoundComponent implements OnInit {
  public authFacade = inject(AuthFacade);
  private router = inject(Router);
  isLoggedIn: boolean;

  ngOnInit() {
    this.authFacade.isLoggedIn$.pipe(untilDestroyed(this)).subscribe((isLoggedIn: boolean) => {
      this.isLoggedIn = isLoggedIn;
    });
  }

  onBack() {
    const url = this.isLoggedIn ? '/' : '/login';
    this.router.navigateByUrl(url);
  }
}
