<mat-form-field class="tw-w-72">
  <mat-label> {{ 'common.legal_form' | translate }} </mat-label>
  <mat-select [formControl]="control()">
    <mat-option
      *ngFor="let legalForm of legalForms"
      [value]="legalForm"
    >
      {{ 'common.legal_forms.' + legalForm | translateWithFallback: legalForm }}
    </mat-option>
  </mat-select>
  <button
    *ngIf="suggestion"
    mat-stroked-button
    matSuffix
    (click)="onHintClick($event)"
  >
    {{ 'common.legal_forms.' + suggestion | translateWithFallback: suggestion }}
  </button>
</mat-form-field>
