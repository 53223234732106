<form [formGroup]="formGroup()">
  <div class="two-col-row">
    <mat-form-field>
      <mat-label> {{ 'card-order-form.new_card' | translate }} </mat-label>
      <input
        formControlName="default_card_price"
        matInput
        currencyMask
        trim
        required
      />
      <button
        mat-stroked-button
        matSuffix
        class="ml1"
        *ngFor="let suggestion of suggestions.default_card_price"
        (click)="onDefaultCardPriceSuggestionClick($event, suggestion)"
      >
        {{ suggestion | currency }}
      </button>
      <mat-error *ngIf="formGroup().controls.default_card_price.errors?.required">
        {{ 'common.fill_out_field' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label> {{ 'common.renew' | translate }} </mat-label>
      <input
        formControlName="default_renew_card_price"
        matInput
        currencyMask
        trim
        required
      />
      <button
        mat-stroked-button
        matSuffix
        *ngFor="let suggestion of suggestions.default_renew_card_price"
        class="ml1"
        (click)="onDefaultRenewCardPriceSuggestionClick($event, suggestion)"
      >
        {{ suggestion | currency }}
      </button>
      <mat-error *ngIf="formGroup().controls.default_renew_card_price.errors?.required">
        {{ 'common.fill_out_field' | translate }}
      </mat-error>
    </mat-form-field>
  </div>
  <span
    *ngIf="multiplier && multiplier['default_card_order_invoice_recipient_type'] === 'customer'"
    class="mt1"
    [innerHTML]="'card-order-form.hint' | translate"
  >
  </span>
  <span
    *ngIf="multiplier && multiplier['default_card_order_invoice_recipient_type'] === 'multiplier'"
    class="mt1"
    [innerHTML]="'card-order-form.hint-2' | translate"
  >
  </span>
  <div class="two-col-row">
    <mat-form-field>
      <mat-label> {{ 'card-order-form.single_shipping' | translate }} </mat-label>
      <input
        formControlName="default_single_shipping_fee"
        matInput
        currencyMask
        trim
        required
      />
      <button
        mat-stroked-button
        matSuffix
        (click)="onDefaultSingleShippingFeeSuggestionClick($event, suggestions.default_single_shipping_fee)"
      >
        {{ suggestions.default_single_shipping_fee | currency }}
      </button>
      <mat-error *ngIf="formGroup().controls.default_single_shipping_fee.errors?.required">
        {{ 'common.fill_out_field' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label> {{ 'card-order-form.bulk_shipping' | translate }} </mat-label>
      <input
        formControlName="default_bulk_shipping_fee"
        matInput
        currencyMask
        trim
        required
      />
      <button
        mat-stroked-button
        matSuffix
        (click)="onDefaultBulkShippingFeeSuggestionClick($event, suggestions.default_bulk_shipping_fee)"
      >
        {{ suggestions.default_bulk_shipping_fee | currency }}
      </button>
      <mat-error *ngIf="formGroup().controls.default_bulk_shipping_fee.errors?.required">
        {{ 'common.fill_out_field' | translate }}
      </mat-error>
    </mat-form-field>
  </div>
</form>
