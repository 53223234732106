import { AsyncPipe, CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatListItem } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterLink, RouterOutlet } from '@angular/router';
import { UserRole } from '@app/api/interfaces/user.interface';
import { AuthFacade } from '@app/auth';
import { environment } from '@app/env';
import { CustomerCreateDialogComponent } from '@app/features/customer-create/customer-create-dialog/customer-create-dialog.component';
import { routerQuery } from '@app/ngrx-router';
import { GlobalSearchFieldComponent } from '@app/shared/components/global-search-field/global-search-field.component';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { FrameModule } from '@givve/ui-kit/features';
import { RouterHistoryService } from '@givve/ui-kit/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store, select } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { combineLatest, map } from 'rxjs';
import { AsNavItemPipe } from '../pipes/as-nav-item.pipe';

const hideGlobalSearchSegments = ['/search'];

@UntilDestroy()
@Component({
  selector: 'app-frame',
  templateUrl: './frame.component.html',
  styleUrls: ['./frame.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatSidenavModule,
    RouterLink,
    GlobalSearchFieldComponent,
    RouterOutlet,
    AsyncPipe,
    FrameModule,
    CommonModule,
    TranslateModule,
    MatMenuModule,
    AsNavItemPipe,
    MatListItem,
    FaIconComponent,
  ],
})
export class FrameComponent implements OnInit {
  private translate = inject(TranslateService);
  public authFacade = inject(AuthFacade);
  public routerHistoryService = inject(RouterHistoryService);
  private store = inject<Store>(Store);
  hideGlobalSearch = false;
  protected dialog = inject(MatDialog);
  protected customerCreateDialogComponent = CustomerCreateDialogComponent;
  protected customerCreateDialogConfig = {
    width: '1550px',
    maxWidth: '95vw',
  };

  isHelpDeskUser$ = combineLatest([
    this.authFacade.userHasRole$(UserRole.SUPERUSER),
    this.authFacade.userHasRole$(UserRole.HELPDESK),
  ]).pipe(map(([isSuperUser, isHelpDesk]) => isSuperUser || isHelpDesk));

  navItems$ = combineLatest([
    this.authFacade.userHasRole$(UserRole.SUPERUSER),
    this.authFacade.userHasRole$(UserRole.TRANSACTION_ASSIGNMENT),
    this.authFacade.userHasRole$(UserRole.HELPDESK),
    this.authFacade.userHasRole$(UserRole.MERCHANT_MANAGER),
  ]).pipe(
    map(([isSuperUser, isTransactionAssignment, isHelpDesk, isMerchantManager]) => {
      return [
        ...(isSuperUser || isHelpDesk
          ? [
              {
                title: this.translate.instant('common.csv_upload'),
                path: '/csv-upload',
                icon: 'upload',
                children: [
                  {
                    path: '/csv-upload',
                    icon: 'upload',
                    title: this.translate.instant('csv_upload.component.frame.general'),
                    routerLinkActiveOptions: {
                      exact: true,
                    },
                  },
                  {
                    path: '/csv-upload',
                    icon: 'load-orders',
                    title: this.translate.instant('csv_upload.component.frame.load_orders'),
                    queryParams: { action: 'load-orders' },
                    routerLinkActiveOptions: {
                      exact: true,
                    },
                  },
                  {
                    path: '/csv-upload',
                    icon: 'order',
                    title: this.translate.instant('csv_upload.component.frame.card_orders'),
                    queryParams: { action: 'card-order' },
                    routerLinkActiveOptions: {
                      exact: true,
                    },
                  },
                ],
              },
            ]
          : []),

        ...(isSuperUser || isTransactionAssignment
          ? [
              {
                title: this.translate.instant('common.book_keeping'),
                path: '/credit-transfers',
                icon: 'suitcase',
                children: [
                  { path: '/credit-transfers', icon: 'suitcase', title: this.translate.instant('common.transfers') },
                ],
              },
            ]
          : []),
        {
          path: '/email-activity',
          title: this.translate.instant('common.email_activity'),
          icon: 'email',
        },
        ...(isSuperUser ? [{ path: '/users', title: this.translate.instant('common.users'), icon: 'user' }] : []),
        ...(isSuperUser
          ? [
              {
                path: '/api-accesses',
                title: this.translate.instant('common.api_accesses'),
                icon: 'settings',
              },
            ]
          : []),
        ...(isSuperUser
          ? [
              {
                path: '/audit-logs',
                title: 'Audit Logs',
                icon: 'error-report',
              },
            ]
          : []),
        ...(isSuperUser || isMerchantManager
          ? [
              {
                path: '/merchants',
                title: this.translate.instant('common.partner_managment'),
                icon: 'store',
              },
            ]
          : []),
        ...(isSuperUser || isHelpDesk
          ? [
              {
                title: this.translate.instant('common.card_designs'),
                icon: 'card-default',
                path: '/card-designs',
              },
            ]
          : []),
        ...(isSuperUser && !environment.production
          ? [
              {
                title: this.translate.instant('common.testing'),
                icon: 'settings',
                path: '/push-notification',
                children: [
                  {
                    path: '/push-notification',
                    title: this.translate.instant('common.push_notification'),
                    icon: 'settings',
                  },

                  {
                    path: '/lunch-tax-rules',
                    title: this.translate.instant('common.lunch_tax_rules'),
                    icon: 'load-orders',
                  },
                ],
              },
            ]
          : []),
      ];
    })
  );

  version = `${environment.version}`;

  ngOnInit() {
    // hide global search in /search segment
    this.store
      .pipe(select(routerQuery.getUrl))
      .pipe(untilDestroyed(this))
      .subscribe((url) => {
        this.hideGlobalSearch = !!hideGlobalSearchSegments.find((segment) => url.includes(segment));
      });
  }

  onLogout() {
    this.authFacade.logout();
  }

  onOldAdminClick() {
    window.location.href = environment.adminUrlV1;
  }
}
